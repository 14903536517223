<script setup>
function reset() {
  document.location.reload();
}
</script>

<template lang="pug">
section.hero.is-fullheight.is-bold.is-info
  .hero-head(style="background-color: white")
    a(@click="reset")
      figure.image.is-centered(style="max-width: 400px;")
        img(src="@/assets/bnb-logo-800.png")
  .hero-body
    .is-flex.is-flex-direction-column
      p.is-size-5
        | Thank you for submitting your information to Beef & Boards Casting!
        | We will review your materials and be in touch should we need to see more.
        | Please feel free to login and update your profile as needed.
        | We appreciate you sharing your talents with our team and thank you for your
        | interest in performing at Beef & Boards Dinner Theatre.
      p(style="margin: 2rem auto")
        OButton(@click="reset") Sign back in
</template>

<style scoped>

</style>
