import '@/utilities/polyfill.js';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { library } from '@fortawesome/fontawesome-svg-core';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import { vTooltip } from 'floating-vue';
import 'floating-vue/dist/style.css';

import { SetupErrorHandling } from '@/error_handling';
import WizardApp from '@/WizardApp.vue';
import Theme from '@/utilities/theme';
import { delayedClean } from '@/utilities/caching';
import { setupVersionCheck } from '@/version';

import {
  faPlus, faMinus, faTimes,
  faArrowUp, faArrowDown,
  faCaretUp, faCaretDown, faCaretLeft, faCaretRight,
  faTimesCircle,
  faExclamationCircle, faExclamationTriangle, faEye, faEyeSlash,
  faTag, faCircleNotch, faAngleLeft, faAngleRight, faSearch,
  faUserPen, faUserCheck, faUserXmark, faUser, faUsers,
  faEnvelope, faPhone, faPenToSquare, faTrash,
  faLock, faSquareCheck, faInfoCircle, faCheck,
  faUpload, faArrowUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faPlus, faMinus, faTimes,
  faArrowUp, faArrowDown,
  faCaretUp, faCaretDown, faCaretLeft, faCaretRight,
  faTimesCircle,
  faExclamationCircle, faExclamationTriangle, faEye, faEyeSlash,
  faTag, faCircleNotch, faAngleLeft, faAngleRight, faSearch,
  faUserPen, faUserCheck, faUserXmark, faUser, faUsers,
  faEnvelope, faPhone, faPenToSquare, faTrash,
  faLock, faSquareCheck, faInfoCircle, faCheck,
  faUpload, faArrowUpRightFromSquare,
);

const app = createApp(WizardApp)
  .use(Theme)
  .use(createPinia())
  .use(VueTelInput, {
    defaultCountry: 'US',
    preferredCountries: ['US'],
    mode: 'national',
    dropdownOptions: {
      showSearchBox: true,
    },
  });
app.directive('tooltip', vTooltip);

SetupErrorHandling(app);

app.mount('#app');

delayedClean();

setupVersionCheck();

if (import.meta.env.MODE === 'development') {
  (async () => {
    // these import are dev only imports
    const api = (await import('@/api'));

    window.App = {
      api,
    };
  })();
}
