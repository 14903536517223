<script setup>
import { useSkillStore } from '@/stores/skills';
import { useActorStore } from '@/stores/actors';
import { confirm, openDialog, showError } from '@/dialogs';
import { computed, inject } from 'vue';
import { groupBy, sortMap } from '@/utilities/helpers.js';
import EditActorSkillsDialog from '@/dialogs/EditActorSkillsDialog.vue';
import { colorProps } from '@/utilities/colors.js';
import { encodeHTML } from 'entities';
import { isGraphQLError } from '@/api/utils.js';
import { ReportError } from '@/error_handling.js';
import { WizardActorEditingKey } from '@/composables/wizard_actor_editing.js';

const { actor } = inject(WizardActorEditingKey);

const skillsStore = useSkillStore();

const skillsByCategory = computed(() => {
  const skills = skillsStore.forActorId(actor.value.id);
  const grouped = groupBy(skills, skill => skill.category);
  return sortMap(grouped, {
    valueSort: (a, b) => a.skill > b.skill ? 1 : -1,
  });
});

const isLoading = computed(() => skillsStore.isLoading);

async function addSkills() {
  await openDialog(EditActorSkillsDialog, { actorId: actor.value.id });
}

async function removeSkill(skill) {
  const result = await confirm(
    {
      message: `Remove the <em>${encodeHTML(skill.category)}: ${encodeHTML(skill.skill)}</em> skill?`,
    });

  if (result.action === 'ok') {
    try {
      await useActorStore().save({
        id: actor.value.id,
        removeSkillIds: [skill.id],
      });
    } catch (ex) {
      if (isGraphQLError(ex)) {
        showError({ message: ex.message });
      }
      ReportError(ex);
    }
  }
}
</script>

<template lang="pug">
OButton(icon-right="plus" rounded @click="addSkills") Add skills

template(v-for="[category, skills] in skillsByCategory" :key="category")
  p.title.is-4.skill_category {{ category }}
  .tags.are-medium
    .tag(
      v-for="skill in skills"
      :key="skill.id"
      :class="colorProps(skill?.options?.colorName)?.classes"
    )
      | {{ skill.skill }}
      button.delete.is-medium(@click="removeSkill(skill)")
OField(label="Other skills")
  OInput(v-model="actor.otherSkills" placeholder="Enter any other skills" type="textarea")
OLoading(:active="isLoading" :full-page="false")
</template>

<style scoped>
.skill_category {
    border-bottom: 1px solid black;
}
</style>
