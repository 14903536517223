<script setup>
import { useAuthStore } from '@/stores/auth';
import { checkForm, setFieldValidity } from '@/utilities/vue';
import { computed, getCurrentInstance, nextTick, onBeforeUnmount, ref } from 'vue';
import { showError, showNotification } from '@/dialogs';

const NOT_ALLOWED_CHARACTERS = /[^23456789BCDFGHJKMNPQRTVWXY]/g;

const emit = defineEmits(['signedin']);

const Modes = Object.freeze({
  SIGNUP: Symbol('SIGNUP'),
  TOKEN: Symbol('TOKEN'),
  VERIFY_EMAIL: Symbol('VERIFY_EMAIL'),
  PASSWORD: Symbol('PASSWORD'),
});

const mode = ref(Modes.SIGNUP);

const email = ref('');
const emailRef = ref(null);
const emailParentRef = ref(null);
const lastEmail = ref('');

const password = ref('');
const passwordRef = ref(null);
const passwordParentRef = ref(null);

const verifyEmail = ref('');
const verifyEmailRef = ref(null);
const verifyEmailParentRef = ref(null);

const token = ref('');
const tokenRequireCheck = ref(true);
const totpCodeParentRef = ref(null);
const totpCodeRef = ref(null);

const disabled = ref(false);
const notification = ref(null);

const signinLabel = computed(() => {
  switch (mode.value) {
  case Modes.TOKEN:
  case Modes.PASSWORD:
    return 'Sign in';
  case Modes.VERIFY_EMAIL:
  case Modes.SIGNUP:
  default:
    return 'Sign up or Sign in with Email';
  }
});

onBeforeUnmount(() => {
  closeNotification();
});

const vm = getCurrentInstance();

// methods
async function sendNewCode() {
  await signup(true);
  await nextTick(() => {
    totpCodeRef.value.focus();
  });
}

async function usePasswordSignin() {
  mode.value = Modes.PASSWORD;
  password.value = '';
}

async function useCodeSignin() {
  mode.value = Modes.SIGNUP;
}

async function signup(skipToken = false) {
  console.log('signup called', skipToken);
  if (mode.value !== Modes.PASSWORD && lastEmail.value !== email.value) {
    mode.value = Modes.SIGNUP;
    verifyEmail.value = '';
    await nextTick();
    lastEmail.value = email.value;
  }
  if (skipToken) {
    tokenRequireCheck.value = false;
    await nextTick();
  }
  await setFieldValidity(verifyEmailParentRef.value);
  closeNotification();
  const invalid = checkForm(vm);
  tokenRequireCheck.value = true;

  if (invalid.length > 0) {
    return;
  }

  disabled.value = true;
  const options = {};
  if (!skipToken && token.value) {
    options.otp = token.value;
  }
  if (verifyEmail.value) {
    options.verify_email = verifyEmail.value;
  }
  if (mode.value === Modes.PASSWORD) {
    options.password = password.value;
  }
  useAuthStore().authenticateWizard(email.value, options).then(() => {
    emit('signedin');
  }).catch((e) => {
    console.error('sign in error', e);
    switch (e.code) {
    case 'AUTHENTICATION_LINK':
      mode.value = Modes.TOKEN;
      token.value = '';
      notification.value = showNotification({
        message: 'Check your email for a sign in code',
        variant: 'warning',
        position: 'top',
        indefinite: true,
        closable: true,
      });
      nextTick(() => {
        totpCodeRef.value.focus();
      });
      break;
    case 'CHECK_EMAIL':
      if (verifyEmail.value) {
        setFieldValidity(verifyEmailParentRef.value, 'danger', 'Email does not match');
      }
      mode.value = Modes.VERIFY_EMAIL;
      notification.value = showNotification({
        message: 'Please re-enter your email to verify',
        variant: 'warning',
        indefinite: true,
        closable: true,
        position: 'top',
      });
      nextTick(() => {
        verifyEmailRef.value.focus();
      });
      break;
    case 'INVALID_EMAIL':
    case 'NO_EMAIL':
      setFieldValidity(emailParentRef.value, 'danger', 'Please enter a valid email');
      nextTick(() => {
        emailRef.value.focus();
      });
      break;
    case 'INVALID_CODE':
      setFieldValidity(totpCodeParentRef.value, 'danger', 'Please enter the code from the email. Or request a new code');
      nextTick(() => {
        totpCodeRef.value.focus();
      });
      break;
    case 'INVALID_PASSWORD':
      setFieldValidity(passwordParentRef.value, 'danger', 'Your email or password are incorrect or you do not have a password set for your profile');
      nextTick(() => {
        passwordRef.value.focus();
      });
      break;
    default:
      notification.value = showError({
        message: 'Failed to signup',
        position: 'top',
      });
    }
  }).finally(() => {
    disabled.value = false;
  });
}

function closeNotification() {
  notification.value?.close();
  notification.value = null;
}

function cleanTokenInput(event) {
  if (event.data) {
    let allowed = event.data.toLocaleUpperCase().replaceAll(NOT_ALLOWED_CHARACTERS, '');
    const existingLength = event.target.value.length - (event.target.selectionEnd - event.target.selectionStart);
    allowed = allowed.substring(0, event.target.maxLength - existingLength);
    event.target.setRangeText(allowed, event.target.selectionStart, event.target.selectionEnd, 'end');
    token.value = event.target.value;
    event.preventDefault();
  }
}
</script>

<template lang="pug">
section.hero
  .hero-head
    figure.image.is-centered(style="max-width: 400px;")
      img(src="@/assets/bnb-logo-800.png")
  .hero-body
    section.notification.is-light.is-info
      p.
        Welcome to Beef & Boards Casting Manager page.
        Thank you for taking the time to put in as little or as much info as you’d like.
        You may come back anytime to update your profile and only need your email address.
      br
      p.
        Beef & Boards will not use any information you provide for anything except
        casting purposes.
    p.title {{ signinLabel }}

    OField(ref="emailParentRef" label="E-Mail")
      OInput(
        ref="emailRef"
        v-model.trim="email"
        type="email"
        icon="envelope"
        placeholder="E-Mail"
        required
        autocomplete="email"
        :disabled="disabled"
        @change="tokenRequired = false"
        @keyup.enter="signup()"
      )
    OField(
      v-if="mode === Modes.VERIFY_EMAIL"
      ref="verifyEmailParentRef"
      label="Verify E-Mail"
    )
      OInput(
        ref="verifyEmailRef"
        v-model.trim="verifyEmail"
        type="email"
        icon="envelope"
        placeholder="Reenter E-Mail"
        required
        autocomplete="off"
        :disabled="disabled"
        @keyup.enter="signup()"
      )
    OField(
      v-else-if="mode === Modes.TOKEN"
      ref="totpCodeParentRef"
      label="Code"
    )
      OInput.totp_code(
        ref="totpCodeRef"
        v-model.trim="token"
        type="text"
        maxlength="5"
        icon="lock"
        placeholder="Enter the 5-character code from your email"
        :required="tokenRequireCheck"
        :disabled="disabled"
        @beforeinput="cleanTokenInput"
        @keyup.enter="signup()"
      )
    OField(
      v-else-if="mode === Modes.PASSWORD"
      ref="passwordParentRef"
      label="Password"
    )
      OInput.password(
        ref="passwordRef"
        v-model="password"
        type="password"
        icon="lock"
        placeholder="Enter your password"
        required
        :disabled="disabled"
        @keyup.enter="signup()"
      )

    .buttons(v-if="mode === Modes.SIGNUP")
      OButton(
        v-tooltip="'A one-time login code will be sent to your email'"
        variant="primary"
        :disabled="disabled"
        @click="signup()"
      ) Sign up or Sign in with Email
      OButton(
        v-tooltip="'Sign in with an email and password instead'"
        variant="secondary"
        :disabled="disabled"
        @click="usePasswordSignin()"
      ) Sign in with a password
    .buttons(v-else-if="mode === Modes.VERIFY_EMAIL")
      OButton(
        v-tooltip="'Re-enter your email address to create your profile'"
        variant="primary"
        :disabled="disabled"
        @click="signup()"
      ) Sign up with Email
    .buttons(v-else-if="mode === Modes.TOKEN")
      OButton(
        v-tooltip="'Enter the code sent to your email'"
        variant="primary"
        :disabled="disabled"
        @click="signup()"
      ) Sign in
      OButton(
        v-tooltip="'Send another email code'"
        variant="secondary"
        :disabled="disabled"
        @click="sendNewCode()"
      ) Send new code
    .buttons(v-else-if="mode === Modes.PASSWORD")
      OButton(
        v-tooltip="'Sign in with email and password'"
        variant="primary"
        :disabled="disabled"
        @click="signup()"
      ) Sign in
      OButton(
        v-tooltip="'Sign in with an email code instead'"
        variant="secondary"
        :disabled="disabled"
        @click="useCodeSignin()"
      ) Sign in with code
</template>

<style scoped>

</style>
