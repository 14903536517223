<script setup>
import { useActorHeadshotStore } from '@/stores/actor_headshots';
import ActorHeadshotCell from '@/cells/ActorHeadshotCell.vue';
import TileTable from '@/components/TileTable.vue';
import { computed } from 'vue';
import AddHeadshotCell from '@/cells/AddHeadshotCell.vue';

const actorHeadshotsStore = useActorHeadshotStore();

const props = defineProps({
  actorId: { type: String, required: true },
});

const actorHeadshots = computed(() => {
  return actorHeadshotsStore.forActorId(props.actorId);
});

const canAddHeadshot = computed(() => {
  return actorHeadshots.value.length < 4;
});
</script>

<template lang="pug">
TileTable(
  :data="actorHeadshots"
  custom-row-key="id"
  :cell-columns="3"
  :last-cell="canAddHeadshot"
  :sort-by="['date','createdAt']"
  sort-order="desc"
  cell-wrapper-class="card card-full-height"
)
  template(#last)
    AddHeadshotCell(:actor-id="props.actorId")
  template(#cell="{cell}")
    ActorHeadshotCell(editable :actor-headshot="cell")
</template>

<style scoped>
</style>
