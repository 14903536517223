<script setup>
import { useActorVideoStore } from '@/stores/actor_videos';
import { computed } from 'vue';
import EditActorVideoDialog from '@/dialogs/EditActorVideoDialog.vue';
import { openDialog } from '@/dialogs';

const actorVideosStore = useActorVideoStore();

const props = defineProps({
  actorId: { type: String, required: true },
});

const actorVideos = computed(() => {
  const records = actorVideosStore.forActorId(props.actorId);
  records.sort((a, b) => a.position - b.position);
  return records;
});

// methods

async function addVideo() {
  const record = {
    actorId: props.actorId,
  };
  await openDialog(EditActorVideoDialog, { record });
}

async function editVideo(videoId) {
  const record = await actorVideosStore.retrieveAsync(videoId);
  await openDialog(EditActorVideoDialog, { record });
}

async function moveRecord(actorVideo, direction) {
  const pos = actorVideos.value.findIndex(e => e.id === actorVideo.id);
  if (pos < 0) return;

  if (direction < 0) {
    if (pos > 0) {
      const id = actorVideos.value[pos - 1].id;

      await actorVideosStore.reorder(actorVideo.id, 'BEFORE', id);
    }
  } else {
    if (pos < (actorVideos.value.length - 1)) {
      const id = actorVideos.value[pos + 1].id;

      await actorVideosStore.reorder(actorVideo.id, 'AFTER', id);
    }
  }
}
</script>

<template lang="pug">
OButton(icon-right="plus" @click="addVideo") Add video/reel
hr
OTable(
  :data="actorVideos"
  custom-row-key="id"
)
  OTableColumn(v-slot="{row}" label="Name" field="name" sortable)
    | {{ row.name }}
  OTableColumn(v-slot="{row}" label="URL" field="videoLink.url" sortable)
    a(:href="row.videoLink.url" target="_blank")
      span.icon-text
        span {{ row.videoLink.url }}
        OIcon(icon="arrow-up-right-from-square")
  OTableColumn(v-slot="{row}" label="Position" field="position")
    .buttons.has-addons
      OButton(rounded size="small" icon-right="caret-up" @click="moveRecord(row, -1)")
      OButton(rounded size="small" icon-right="caret-down" @click="moveRecord(row, 1)")
  OTableColumn(v-slot="{row}")
    OButton(size="small" icon-right="pen-to-square" @click="editVideo(row.id)") Edit
</template>

<style scoped>
</style>
